import { PLATFORM } from "../constant";
import isMobile from "./check-mobile";

const getPlatform = (query: any) => {
  const isPc = !!Number(query.get("ispc"));
  if (isPc) {
    return PLATFORM.PC_WEB_H5;
  }
  if (isMobile()) {
    if (navigator.userAgent.toLocaleLowerCase().indexOf("zalo") > -1) {
      return PLATFORM.IN_APP_H5;
    }
    return PLATFORM.BROWSER_MOBILE;
  }
  return PLATFORM.BROWSER_PC;
};

export default getPlatform;
