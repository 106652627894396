import { onFetch, onError, onSuccess } from "./action";

import {
  InfoActionTypes,
  ZThunkAction,
  CODE,
} from "../../z-types/redux";

export const fetchInfo =
  (pid: string): ZThunkAction<void, InfoActionTypes> =>
  async (dispatch, _, { zCatalogService }) => {
    dispatch(onFetch());
    try {
      let res: any = (await zCatalogService.getInfo(pid));
      if (res.error_code === CODE.SUCCESS) {
        if (res.error_code === CODE.ERROR_INFO_NOTFOUND) {
          dispatch(onError(res));
        } else {
          dispatch(
            onSuccess({
              ...res,
              data: {
                product_info: {
                  ...res.data.product_info,
                  pid,
                },
              },
            })
          );
        }
      } else {
        dispatch(onError(res));
      }
    } catch (err) {
      dispatch(onError(err));
    }
  };
