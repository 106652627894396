import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// @ts-ignore
import ZJSBridge from "zalo-js-bridge";

import * as InfoThunkActions from "../../redux/info/thunk";
import { InfoStateI, ZThunkDispatch } from "../../z-types/redux";
import { RootState } from "../../redux/root-reducer";

// import components
import Share from "../../components/share";
import ContactBtn from "../../components/contact-btn";
import Spinner from "../../components/spinner";
import { NOT_FOUND_TYPE } from "../not-found/not-found";

// utils
import zCatalogServiceInstance from "../../services/z-catalog-service";
import getPrice from "../../utils/get-price";
import getPlatform from "../../utils/get-platform";
import {
  ACTIONS,
  OPEN_SOURCE,
  USER_TYPES,
  THUMB_FAIL_LINK,
} from "../../constant";

import "./style.scss";

const owlClass = "product-detail";
const buttonSide = "button-side";

var parentWindow = window.parent;

function SampleNextArrow(props: any) {
  const { onClick, onTrackingAction, isDisabled } = props;
  const isClickedRef = useRef<boolean>(false);

  return (
    <div
      className={`${buttonSide} ${buttonSide}--right${
        isDisabled ? ` ${buttonSide}--is-disabled` : ""
      }`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
        if (!isClickedRef.current && onTrackingAction) {
          onTrackingAction(ACTIONS.SWIPE_IMAGE);
          isClickedRef.current = true;
        }
      }}
    >
      <img
        className={`${buttonSide}__arrow ${buttonSide}__arrow--right`}
        src="https://stc-zh5.zdn.vn/catalog/right-arrow.svg"
        alt="right-arrow"
      />
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick, isDisabled } = props;
  return (
    <div
      className={`${buttonSide} ${buttonSide}--left${
        isDisabled ? ` ${buttonSide}--is-disabled` : ""
      }`}
      onClick={onClick}
    >
      <img
        className={`${buttonSide}__arrow ${buttonSide}__arrow--left`}
        src="https://stc-zh5.zdn.vn/catalog/left-arrow.svg"
        alt="left-arrow"
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  info: state.info as InfoStateI,
});

const mapDispatchToProps = (dispatch: ZThunkDispatch) => {
  return {
    fetchInfoData: (pid: string) => dispatch(InfoThunkActions.fetchInfo(pid)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ProductDetailProps = PropsFromRedux;

const ProductDetail: React.FC<ProductDetailProps> | null = ({
  info,
  fetchInfoData,
}) => {
  const location = useLocation<{
    fromCategoryPage: false;
  }>();
  const { t, i18n } = useTranslation();

  const query = new URLSearchParams(useLocation().search);
  const pid = query.get("pid");
  const entryPoint = query.get("zarsrc");
  const [inApp] = useState(
    window.navigator.userAgent.toLocaleLowerCase().indexOf("zalo") > -1
  );
  const [currentSlide, setCurrentSlide] = useState(1);
  const isTrackedPageView = useRef<boolean>(false);

  const isShowGroupBtn = useMemo(
    () =>
      navigator.userAgent.toLocaleLowerCase().indexOf("zalo") > -1 ||
      document.referrer.includes("chat.zalo.me"),
    []
  );

  const onTrackingAction = async (action: string) => {
    if (!info || !info.data) return;

    try {
      if (!info || !info.data) return;
      await zCatalogServiceInstance.logAction(
        JSON.parse(
          JSON.stringify({
            productId: info.data.data.product_info.id,
            catalogId: info.data.data.product_info.catalogId,
            ownerId: info.data.data.product_info.ownerId,
            userId: info.data.data.product_info.uid,
            platform: getPlatform(query),
            userType: info.data.data.product_info.me
              ? USER_TYPES.SELLER
              : USER_TYPES.BUYER,
            openSource:
              action === ACTIONS.PRODUCT_VIEW
                ? location.state?.fromCategoryPage
                  ? OPEN_SOURCE.FROM_CATALOG
                  : OPEN_SOURCE.OTHER
                : undefined,
            action,
            entryPoint,
          })
        )
      );
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const settings = useMemo(() => {
    const slides = info.data?.data?.product_info?.productPhotos?.length || 1;

    return {
      dots: false,
      infinite: false,
      speed: 300,
      swipe: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (currentSlide: number) => {
        setCurrentSlide(currentSlide + 1);
      },
      prevArrow: <SamplePrevArrow isDisabled={!(currentSlide > 1)} />,
      nextArrow: (
        <SampleNextArrow
          onTrackingAction={onTrackingAction}
          isDisabled={!(currentSlide < slides)}
        />
      ),
    };
  }, [currentSlide, info.data]);

  useEffect(() => {
    if (i18n.isInitialized && inApp) {
      ZJSBridge.H5.changeTitleHeader(t("product-detail"));
    }
  }, [i18n.isInitialized, inApp]);

  // check route
  useEffect(() => {
    if (pid) {
      const isFetched = !!info.data && info.data.data.product_info.pid === pid;
      if (!isFetched) {
        fetchInfoData(pid);
      }
    } else {
      <Redirect
        to={{
          pathname: "/not-found",
          state: { typeNotFound: NOT_FOUND_TYPE.GENERAL },
        }}
      />;
    }
  }, []);

  useEffect(() => {
    if (
      info &&
      info.data &&
      info.data.data?.product_info?.id &&
      !isTrackedPageView.current
    ) {
      onTrackingAction(ACTIONS.PRODUCT_VIEW);
      isTrackedPageView.current = true;
    }
  }, [info]);

  const onClickImage = useCallback(
    (urlSrc: string) => {
      if (!info.data || urlSrc === THUMB_FAIL_LINK) return;
      const postData = {
        actionType: {
          action: "open_photo",
        },
        data: {
          url_src: info.data.data.product_info.productPhotos,
          select_current: urlSrc,
          product_name: info.data.data.product_info.name,
        },
      };
      parentWindow.postMessage(postData, "*");
    },
    [info]
  );

  if (!info.data) return null;

  if (info.isLoading) return <Spinner />;

  if (info.error) {
    return (
      <Redirect
        to={{
          pathname: "/not-found",
          state: { typeNotFound: NOT_FOUND_TYPE.GENERAL },
        }}
      />
    );
  }

  const renderBtnGroup = () => {
    if (!info.data) return null;

    if (isShowGroupBtn) {
      return (
        <>
          <div
            style={{
              height: "56px",
            }}
          />
          <div className={`${owlClass}__container__group-btn`}>
            <div className={`${owlClass}__container__group-btn__item`}>
              <Share onTrackingAction={onTrackingAction} />
            </div>
            <div
              className={`${owlClass}__container__group-btn__item`}
              style={{
                marginLeft: "8px",
              }}
            >
              <ContactBtn
                pid={pid || ""}
                info={info.data}
                onTrackingAction={onTrackingAction}
              />
            </div>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <div className={owlClass}>
      <div className={`${owlClass}__container`}>
        <div className={`${owlClass}__container__slide`}>
          <Slider {...settings}>
            {!!info.data.data.product_info.productPhotos &&
            !!info.data.data.product_info.productPhotos.length ? (
              info.data.data.product_info.productPhotos.map(
                (urlSrc: string, i: number) => (
                  <div
                    key={`${urlSrc}-${i}`}
                    className={`${owlClass}__container__slide__image${
                      isShowGroupBtn ? "" : " is-desktop"
                    }`}
                    onClick={() => onClickImage(urlSrc)}
                  >
                    <img
                      src={urlSrc}
                      onError={(e: any) =>
                        (e.target.src =
                          "https://stc-zh5.zdn.vn/catalog/thumb-fail.png")
                      }
                      alt="..."
                    />
                  </div>
                )
              )
            ) : (
              <div
                className={`${owlClass}__container__slide__image${
                  isShowGroupBtn ? " full-width" : ""
                }`}
                onClick={() =>
                  onClickImage("https://stc-zh5.zdn.vn/catalog/thumb-fail.png")
                }
              >
                <img
                  src="https://stc-zh5.zdn.vn/catalog/thumb-fail.png"
                  alt="..."
                />
              </div>
            )}
          </Slider>
          {(info.data?.data.product_info.productPhotos?.length || 1) > 1 && (
            <div className={`${owlClass}__container__slide__indicator`}>
              {`${currentSlide}/${info.data?.data.product_info.productPhotos?.length}`}
            </div>
          )}
        </div>

        <div className={`${owlClass}__container__info`}>
          <h1 className={`${owlClass}__container__info__name`}>
            {info.data.data.product_info.name}
          </h1>
          <p className={`${owlClass}__container__info__price`}>
            {getPrice(
              info.data.data.product_info.strPrice ||
                info.data.data.product_info.price,
              t
            )}
          </p>
          <hr className={`${owlClass}__container__info__divide`} />
          <div className={`${owlClass}__container__info__seller`}>
            <span className={`${owlClass}__container__info__seller__label`}>
              {t("seller")}:{" "}
            </span>
            {info.data.data.product_info.ownerAvatarUrl && (
              <img
                src={info.data.data.product_info.ownerAvatarUrl}
                className={`${owlClass}__container__info__seller__avatar`}
                alt=""
              />
            )}
            {info.data.data.product_info.ownerDisplayName && (
              <span className={`${owlClass}__container__info__seller__name`}>
                {info.data.data.product_info.ownerDisplayName}
              </span>
            )}
          </div>
          <h3 className={`${owlClass}__container__info__description`}>
            {t("product-description")}
          </h3>
          <div
            className={`${owlClass}__container__info__content`}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(info.data.data.product_info.description),
            }}
          />
        </div>
        {renderBtnGroup()}
      </div>
    </div>
  );
};

export default connector(ProductDetail);
