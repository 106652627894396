import { combineReducers } from "redux";
import infoReducer from "./info/reducer";
import categoryReducer from "./category/reducer";

const rootReducer = combineReducers({
    info: infoReducer,
    category: categoryReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer