import { createStore, applyMiddleware, Store } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./root-reducer";

import zCatalogService from "../services/z-catalog-service";
import { ZThunkExtraArgument } from "../z-types/redux";

const thunkExtraArgument: ZThunkExtraArgument = {
  zCatalogService,
};

const middleware = [thunk.withExtraArgument(thunkExtraArgument)];

const store: Store = createStore(
  rootReducer,
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(...middleware))
    : applyMiddleware(...middleware)
);

export default store;
