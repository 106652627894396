import { CODE } from "./info";

export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const FETCH_CATEGORY_LOAD_MORE = "FETCH_CATEGORY_LOAD_MORE";
export const FETCH_CATEGORY_ON_SUCCESS = "FETCH_CATEGORY_ON_SUCCESS";
export const FETCH_CATEGORY_ON_ERROR = "FETCH_CATEGORY_ON_ERROR";

export interface FetchCategoryAction {
  type: typeof FETCH_CATEGORY | typeof FETCH_CATEGORY_LOAD_MORE;
}

export interface FetchedCategoryOnSuccessAction {
  type: typeof FETCH_CATEGORY_ON_SUCCESS;
  payload: CategoryI;
}

export interface FetchedCategoryOnErrorAction {
  type: typeof FETCH_CATEGORY_ON_ERROR;
  payload: CategoryI;
}

export type CategoryActionTypes =
  | FetchCategoryAction
  | FetchedCategoryOnSuccessAction
  | FetchedCategoryOnErrorAction;

export interface CategoryPaginationI {
  lastId: number;
  limit: number;
}

export interface CategoryDataI {
  catalogId: number;
  currencyUnit: string;
  id: number;
  name: string;
  path: string;
  photos: string[];
  price: number;
  strPrice: string;
}

export interface CategoryI {
  data: {
    category_product: CategoryDataI[];
    catalog_name: string;
    uid: number;
    ownerId: number;
    me: boolean;
    lastId?: number;
    total?: number;
  };
  error_code: CODE;
  error_message: string;
}

export interface CategoryStateI {
  data: CategoryI | null;
  isLoading: boolean;
  isLoadingMore: boolean;
  error: boolean;
}
