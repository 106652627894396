import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { RootState } from "../../redux/root-reducer";
import { CategoryStateI, InfoStateI } from "../../z-types/redux";
import { connect, ConnectedProps } from "react-redux";

import ZCatalogServiceInstance from "../../services/z-catalog-service";

import { PLATFORM } from "../../constant";
// @ts-ignore
import ZJSBridge from "zalo-js-bridge";
import "./style.scss";

const owlClass = "not-found";

export enum NOT_FOUND_TYPE {
  GENERAL = "general",
  CATEGORY_NOT_FOUND = "category-not-found",
}

const isMobile = () =>
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/IEMobile/i) ||
  navigator.userAgent.match(/Opera Mini/i) ||
  navigator.userAgent.match(/Windows Phone/i);

const getPlatform = () => {
  if (isMobile()) {
    if (window.navigator.userAgent.toLocaleLowerCase().indexOf("zalo") > -1) {
      return PLATFORM.IN_APP_H5;
    }
    return PLATFORM.BROWSER_MOBILE;
  }

  if (
    !window.navigator.userAgent ||
    window.navigator.userAgent.toLocaleLowerCase().indexOf("zalo") < 0
  ) {
    if (!document.referrer) {
      return PLATFORM.BROWSER_PC;
    }
    return PLATFORM.UNKNOWN;
  } else {
    return PLATFORM.PC_WEB_H5;
  }
};

const mapStateToProps = (state: RootState) => ({
  category: state.category as CategoryStateI,
  info: state.info as InfoStateI,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type NotFoundProps = PropsFromRedux;

const NotFound: React.FC<NotFoundProps> = ({ category, info }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation<{
    typeNotFound: NOT_FOUND_TYPE.GENERAL;
    categoryName: "";
  }>();

  const [path, setPath] = useState<{
    typeNotFound: NOT_FOUND_TYPE.GENERAL;
    categoryName: "";
  }>();
  const [inApp] = useState(
    window.navigator.userAgent.toLocaleLowerCase().indexOf("zalo") > -1
  );
  useEffect(() => {
    if (i18n.isInitialized) {
      if (inApp) {
        ZJSBridge.H5.changeTitleHeader(
          path?.typeNotFound === NOT_FOUND_TYPE.GENERAL
            ? t("info")
            : t("category")
        );
      }
      setPath({
        typeNotFound: location.state?.typeNotFound || NOT_FOUND_TYPE.GENERAL,
        categoryName: location.state?.categoryName,
      });
    }
  }, [i18n.isInitialized, inApp]);

  useEffect(() => {
    async function logAction() {
      const uid =
        path?.typeNotFound === NOT_FOUND_TYPE.GENERAL
          ? info.data?.data?.product_info?.uid || info.data?.data?.uid || ""
          : category.data?.data.uid || "";
      await ZCatalogServiceInstance.logAction({
        uid,
        platform: getPlatform(),
        userType: 0,
        action: "PAGE_NOT_FOUND",
      });
    }
    if (path) {
      logAction();
    }
  }, [path]);
  return (
    <div className={owlClass}>
      {!!path?.categoryName ? (
        <>
          <h1 className={`${owlClass}__category-name`}>{path?.categoryName}</h1>
          <div className={`${owlClass}__top`}>
            <img
              className={`${owlClass}__top__img`}
              src="https://stc-zh5.zdn.vn/catalog/category-empty-box.svg"
              alt=""
            />
            <span className={`${owlClass}__top__description is-category`}>
              {t("404-category-description")}
            </span>
          </div>
        </>
      ) : (
        <div className={`${owlClass}__top`}>
          <img
            className={`${owlClass}__top__img`}
            src="https://stc-zh5.zdn.vn/catalog/info-error-box.svg"
            alt=""
          />
          <p className={`${owlClass}__top__title`}>{t("404-title")}</p>
          <span className={`${owlClass}__top__description`}>
            {t("404-description")}
          </span>
        </div>
      )}

      <div className={`${owlClass}__bottom`} />
    </div>
  );
};

export default connector(NotFound);
