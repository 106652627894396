import { Reducer } from "react";
import {
  FETCH_INFO,
  FETCH_INFO_ON_ERROR,
  FETCH_INFO_ON_SUCCESS,
  InfoActionTypes,
  InfoStateI,
} from "../../z-types/redux";

const initialState = {
  data: null,
  isLoading: false,
  error: false,
};

const infoReducer: Reducer<InfoStateI, InfoActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_INFO: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_INFO_ON_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    }
    case FETCH_INFO_ON_ERROR: {
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default infoReducer;
