import { onFetch, onError, onSuccess } from "./action";

import {
  CategoryActionTypes,
  ZThunkAction,
  CODE,
  CategoryPaginationI,
} from "../../z-types/redux";

export const fetchCategory =
  (
    cid: string,
    pagination: CategoryPaginationI
  ): ZThunkAction<void, CategoryActionTypes> =>
  async (dispatch, _, { zCatalogService }) => {
    dispatch(onFetch(pagination.lastId > 1));
    try {
      let res: any = (await zCatalogService.getCategory(
        cid,
        pagination
      ));
      if (res.error_code === CODE.SUCCESS) {
        if (res.error_code === CODE.ERROR_INFO_NOTFOUND) {
          dispatch(onError(res));
        } else {
          dispatch(
            onSuccess({
              ...res,
              data: {
                ...res.data,
                lastId: pagination.lastId,
              },
            })
          );
        }
      } else {
        dispatch(onError(res));
      }
    } catch (err) {
      dispatch(onError(err));
    }
  };
