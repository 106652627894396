import { useLocation } from "react-router-dom";
import Category from "../category";
import NotFound from "../not-found";
import ProductDetail from "../product-detail";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const pid = query.get("pid");
  const cid = query.get("cid");

  if (pid) return <ProductDetail />;
  if (cid) return <Category />;

  return <NotFound />;
};

export default Home;
