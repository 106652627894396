import { Reducer } from "react";
import {
  FETCH_CATEGORY,
  FETCH_CATEGORY_LOAD_MORE,
  FETCH_CATEGORY_ON_ERROR,
  FETCH_CATEGORY_ON_SUCCESS,
  CategoryActionTypes,
  CategoryStateI,
} from "../../z-types/redux";

const initialState = {
  data: null,
  isLoading: false,
  isLoadingMore: false,
  error: false,
};

// @ts-ignore
const categoryReducer: Reducer<CategoryStateI, CategoryActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_CATEGORY: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_CATEGORY_LOAD_MORE: {
      return {
        ...state,
        isLoadingMore: true,
      };
    }
    case FETCH_CATEGORY_ON_SUCCESS: {
      const {
        data: { lastId },
      } = action.payload;

      if (lastId && lastId !== 0 && state.data) {
        let {
          data: { category_product },
        } = action.payload;
        category_product = [
          ...state.data?.data.category_product,
          ...category_product,
        ];
        return {
          ...state,
          isLoadingMore: false,
          data: {
            ...action.payload,
            data: {
              ...state.data.data,
              category_product,
            },
          },
        };
      }
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    }
    case FETCH_CATEGORY_ON_ERROR: {
      return {
        ...state,
        isLoading: false,
        isLoadingMore: false,
        data: action.payload,
        error: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default categoryReducer;
