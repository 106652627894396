import {
  FETCH_CATEGORY,
  FETCH_CATEGORY_LOAD_MORE,
  FETCH_CATEGORY_ON_SUCCESS,
  FETCH_CATEGORY_ON_ERROR,
  FetchCategoryAction,
  FetchedCategoryOnSuccessAction,
  FetchedCategoryOnErrorAction,
  CategoryI,
} from "../../z-types/redux";

export const onFetch = (isLoadMore: boolean): FetchCategoryAction => {
  return {
    type: isLoadMore ? FETCH_CATEGORY_LOAD_MORE : FETCH_CATEGORY,
  };
};

export const onSuccess = (res: CategoryI): FetchedCategoryOnSuccessAction => {
  return {
    type: FETCH_CATEGORY_ON_SUCCESS,
    payload: res,
  };
};

export const onError = (err: any): FetchedCategoryOnErrorAction => {
  return {
    type: FETCH_CATEGORY_ON_ERROR,
    payload: err,
  };
};
