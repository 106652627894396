import {
  FETCH_INFO,
  FETCH_INFO_ON_SUCCESS,
  FETCH_INFO_ON_ERROR,
  FetchInfoAction,
  FetchedInfoOnSuccessAction,
  FetchedInfoOnErrorAction,
  InfoI,
} from "../../z-types/redux";

export const onFetch = (): FetchInfoAction => {
  return {
    type: FETCH_INFO,
  };
};

export const onSuccess = (res: InfoI): FetchedInfoOnSuccessAction => {
  return {
    type: FETCH_INFO_ON_SUCCESS,
    payload: res,
  };
};

export const onError = (err: any): FetchedInfoOnErrorAction => {
  return {
    type: FETCH_INFO_ON_ERROR,
    payload: err,
  };
};
