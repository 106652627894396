import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { RootState } from "../../redux/root-reducer";
import {
  CategoryDataI,
  CategoryPaginationI,
  CategoryStateI,
  ZThunkDispatch,
} from "../../z-types/redux";
import * as CategoryThunkActions from "../../redux/category/thunk";
import { connect, ConnectedProps } from "react-redux";

// @ts-ignore
import ZJSBridge from "zalo-js-bridge";

// components
import CategoryCard from "../../components/category-card";
import Spinner from "../../components/spinner";
import { NOT_FOUND_TYPE } from "../not-found/not-found";

// utils
import getPrice from "../../utils/get-price";
import isMobile from "../../utils/check-mobile";

import "./style.scss";
import ZCatalogServiceInstance from "../../services/z-catalog-service";
import getPlatform from "../../utils/get-platform";
import { ACTIONS, USER_TYPES } from "../../constant";

const owlClass = "category";

const Loading = () => (
  <div className="loading">
    <svg
      width="32"
      height="32"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#0068ff"
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
        transform="rotate(275.845 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  </div>
);

const mapStateToProps = (state: RootState) => ({
  category: state.category as CategoryStateI,
});

const mapDispatchToProps = (dispatch: ZThunkDispatch) => {
  return {
    fetchCategoryData: (cid: string, pagination: CategoryPaginationI) =>
      dispatch(CategoryThunkActions.fetchCategory(cid, pagination)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type CategoryProps = PropsFromRedux;

const Category: React.FC<CategoryProps> | null = ({
  category,
  fetchCategoryData,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const query = new URLSearchParams(useLocation().search);
  const cid = query.get("cid");
  const entryPoint = query.get("zarsrc");
  const [inApp] = useState(
    window.navigator.userAgent.toLocaleLowerCase().indexOf("zalo") > -1
  );

  const isTrackedPageView = useRef<boolean>(false);

  const isReachedBottomRef = useRef<boolean>(false);
  const [isRequestLoadMore, setIsRequestLoadMore] = useState(false);
  const [pagination, setPagination] = useState<CategoryPaginationI>({
    lastId: 0,
    limit: 20,
  });

  const isScrolling = () => {
    if (
      window.innerHeight + window.pageYOffset >=
      document.body.offsetHeight - 2
    ) {
      isReachedBottomRef.current = true;
      setIsRequestLoadMore(true);
    } else {
      isReachedBottomRef.current = false;
      setIsRequestLoadMore(false);
    }
  };

  useEffect(() => {
    if (cid) {
      if (category.data === null) {
        fetchCategoryData(cid, pagination);
      } else {
        setPagination({
          ...pagination,
          lastId: category.data.data.lastId || 0,
        });
      }
    } else {
      <Redirect
        to={{
          pathname: "/not-found",
          state: { typeNotFound: NOT_FOUND_TYPE.CATEGORY_NOT_FOUND },
        }}
      />;
    }
    window.addEventListener("scroll", isScrolling);
    return () => window.removeEventListener("scroll", isScrolling);
  }, []);

  useEffect(() => {
    if (isReachedBottomRef.current && isRequestLoadMore) {
      const { limit } = pagination;
      const categoryProduct = category.data?.data.category_product;

      if (categoryProduct && categoryProduct.length) {
        const id = Math.min(...categoryProduct.map((cate) => cate.id));
        setPagination({
          limit,
          lastId: id,
        });
      }
    }
  }, [isReachedBottomRef, isRequestLoadMore]);

  useEffect(() => {
    const { lastId } = pagination;
    if (lastId > 0 && isReachedBottomRef.current) {
      if (
        category.data?.data.total &&
        category.data?.data.total > category.data?.data.category_product.length
      ) {
        if (category.isLoadingMore) return;
        fetchCategoryData(cid || "", pagination);
        isReachedBottomRef.current = false;
        setIsRequestLoadMore(false);
      }
    }
  }, [pagination]);

  useEffect(() => {
    if (i18n.isInitialized && inApp) {
      ZJSBridge.H5.changeTitleHeader(t("category"));
    }
  }, [i18n.isInitialized, inApp]);

  const onTrackingAction = async () => {
    try {
      await ZCatalogServiceInstance.logAction({
        catalogId: category.data?.data.category_product[0].catalogId,
        userId: category.data?.data.uid,
        ownerId: category.data?.data.ownerId,
        platform: getPlatform(query),
        userType: category.data?.data.me ? USER_TYPES.SELLER : USER_TYPES.BUYER,
        action: ACTIONS.CATALOG_VIEW,
        entryPoint,
      });
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    if (
      category &&
      category.data &&
      category.data.data &&
      category.data.data?.category_product &&
      Object.values(category.data.data?.category_product).length &&
      !isTrackedPageView.current
    ) {
      onTrackingAction();
      isTrackedPageView.current = true;
    }
  }, [category]);

  if (!category.data) return null;

  if (category.isLoading) return <Spinner />;

  if (category.error) {
    return (
      <Redirect
        to={{
          pathname: "/not-found",
          state: { typeNotFound: NOT_FOUND_TYPE.CATEGORY_NOT_FOUND },
        }}
      />
    );
  }

  if (Object.values(category.data.data.category_product).length === 0) {
    return (
      <Redirect
        to={{
          pathname: "/not-found",
          state: {
            typeNotFound: NOT_FOUND_TYPE.CATEGORY_NOT_FOUND,
            categoryName: category.data.data.catalog_name || "",
          },
        }}
      />
    );
  }

  return (
    <div className={owlClass}>
      <div className={`${owlClass}__container`}>
        <h1 className={`${owlClass}__container__title`}>
          {category.data.data.catalog_name}
        </h1>
        <div className={`${owlClass}__container__list`}>
          {category.data.data.category_product.map(
            (cate: CategoryDataI, idx: number) => (
              <CategoryCard
                key={`${cate.id}-${idx}`}
                image={cate.photos[0]}
                name={cate.name}
                productId={cate.id}
                price={getPrice(cate.strPrice || cate.price, t)}
                path={isMobile() ? cate.path : `${cate.path}&ispc=1`}
              />
            )
          )}
        </div>
      </div>
      {category.isLoadingMore && <Loading />}
    </div>
  );
};

export default connector(Category);
