import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { InfoI } from "../../z-types/redux";

// @ts-ignore
import ZJSBridge from "zalo-js-bridge";
import zCatalogServiceInstance from "../../services/z-catalog-service";
import {
  ACTIONS,
  SEND_MSG_ANDROID_MAX_VERSION,
  SEND_MSG_IOS_MAX_VERSION,
} from "../../constant";
import getPrice from "../../utils/get-price";

import "./style.scss";

interface ContactBtnProps {
  pid: string;
  info: InfoI;
  onTrackingAction: (action: string) => void;
}

const owlClass = "contact-btn";

const parentWindow = window.parent;
let linkOrigin = window.location.origin;

export const getZVersion = (ua: string): string => {
  if (ua && (ua.includes("android/") || ua.includes("ios/"))) {
    const version =
      ua
        .split(" ")
        .find(
          (item: string) => item.includes("android/") || item.includes("ios/")
        ) || "";
    if (version) {
      return version.split("/")[1];
    }
  }
  return "";
};

const ContactBtn: React.FC<ContactBtnProps> = ({
  pid,
  info,
  onTrackingAction,
}) => {
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const linkText = useMemo(
    () => `${linkOrigin}/?pid=${query.get("pid")}`,
    [query]
  );

  const onClick = useCallback(async () => {
    const isPc = !!Number(query.get("ispc"));
    onTrackingAction(ACTIONS.CONTACT_SELLER);
    if (isPc) {
      const postData = {
        actionType: {
          action: "clickContact",
        },
        data: {
          noiseUserId: info.data.product_info.noisedUserId,
          linkText,
          globalId: "",
          productId: pid,
        },
      };
      parentWindow.postMessage(postData, "*");
    } else {
      if (info.data.product_info.me) {
        ZJSBridge.Device.showToast(t("contact-toast"));
        return;
      }

      const zVersion = getZVersion(navigator.userAgent.toLowerCase());
      const maxVersion = navigator.userAgent.includes("android/")
        ? SEND_MSG_ANDROID_MAX_VERSION
        : SEND_MSG_IOS_MAX_VERSION;

      if (+zVersion % 1000 >= maxVersion) {
        const productCatalogInfo = {
          productId: info.data.product_info.id,
          catalogId: info.data.product_info.catalogId,
          sellerId: info.data.product_info.ownerId,
        };
        ZJSBridge.Zalo.openChat({
          type: 1,
          uId: info.data.product_info.mobileNoisedId,
          appId: process.env.REACT_APP_APP_ID,
          msg: "Mình muốn tìm hiểu về sản phẩm này",
          linkAttachment: {
            title: info.data.product_info.name,
            desc: getPrice(
              info.data.product_info.strPrice || info.data.product_info.price,
              t
            ),
            href: linkText,
            thumb:
              info.data.product_info.productPhotos &&
              info.data.product_info.productPhotos.length > 0
                ? info.data.product_info.productPhotos[0]
                : "https://stc-zh5.zdn.vn/catalog/thumb-fail.png",
            src: process.env.REACT_APP_CATALOG_LIVE,
            tType: 1,
            tHeight: 480,
            tWidth: 480,
            type: 7,
            action: "recommened.link",
            media: {
              streamUrl: "",
              stream_icon: "",
              mediaTitle: info.data.product_info.name,
              artist: "",
              src: linkOrigin,
              count: "",
              type: 7,
              productCatalogInfo,
            },
            // media: `{\"streamUrl\":\"\",\"stream_icon\":\"\",\"mediaTitle\":\"${info.data.product_info.name}\",\"artist\":\"\",\"src\":\"${linkOrigin}\",\"count\":\"\",\"type\":7,\"productCatalogInfo\":\"${productCatalogInfo}\"}`,
          },
        });
      } else {
        try {
          if (!pid) return;
          await zCatalogServiceInstance.sendLink(pid);
          ZJSBridge.Zalo.openChat({
            type: 1,
            uId: info.data.product_info.mobileNoisedId,
            appId: process.env.REACT_APP_APP_ID,
          });
        } catch (error) {
          console.error("error: ", error);
        }
      }
    }
  }, [query, pid, info, linkText, t]);

  return (
    <button className={owlClass} aria-disabled="true" onClick={onClick}>
      <label className={`${owlClass}__label`}>{t("contact-seller")}</label>
    </button>
  );
};

export default ContactBtn;
