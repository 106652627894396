/* eslint-disable @typescript-eslint/no-useless-constructor */
import ZBaseService from "./z-base-service";
import { CategoryPaginationI } from "../z-types/redux";
import getParamValue from "../utils/get-param";

export class ZCatalogService extends ZBaseService {
  constructor(domain: string) {
    super(domain);
  }
  getInfo = async (id: string) => {
    const globalId = getParamValue("globalId");

    const entry = `/v1/product?productId=${id}${
      globalId ? `&globalId=${globalId}` : ""
    }`;
    return this.get(entry);
  };

  getCategory = async (id: string, pagination: CategoryPaginationI) => {
    const globalId = getParamValue("globalId");

    const convertPagination = Object.entries(pagination)
      .map((item) => item.join("="))
      .join("&");

    const entry = `/v1/catalog?noise=${id}&${convertPagination}${
      globalId ? `&globalId=${globalId}` : ""
    }`;
    return this.get(entry);
  };

  sendLink = async (pid: string) => {
    const entry = "/v1/ajax/open_chat_mobile";
    return this.post(entry, {
      data: {
        productId: pid,
      },
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  };
  logAction = async (payload: any) => {
    const entry = "/v1/ajax/log";
    return this.post(entry, {
      data: payload,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  };
}

const ZCatalogServiceInstance = new ZCatalogService(
  process.env.REACT_APP_CATALOG_API as string
);

export default ZCatalogServiceInstance;
