import { AxiosRequestConfig, Method } from "axios";
import zAxios from "../utils/z-axios";

class ZBaseService {
  domain: string;
  constructor(domain: string) {
    this.domain = domain;
  }
  request = async (
    entry: string,
    method: Method,
    options: AxiosRequestConfig = {}
  ) => {
    return new Promise((resolve, reject) => {
      zAxios
        .request({
          baseURL: this.domain,
          url: entry,
          method: method,
          headers: options.headers,
          params: options.params,
        })
        .then((zAxiosResponse) => {
          resolve(zAxiosResponse.data);
        })
        .catch((error) => {
          reject({
            error_message: JSON.stringify(error),
          });
        });
    });
  };

  get = async (entry: string, options?: AxiosRequestConfig) => {
    return this.request(entry, "GET", options);
  };

  post = async (entry: string, options: AxiosRequestConfig) => {
    return new Promise((resolve, reject) => {
      const data = Object.keys(options.data)
        .map((key) => `${key}=${encodeURIComponent(options.data[key])}`)
        .join("&");
      const url = `${this.domain}${entry}`;

      zAxios({
        method: "POST",
        headers: options.headers,
        url,
        data,
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}

export default ZBaseService;
