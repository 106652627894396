export enum USER_TYPES {
  UNKNOWN = 0,
  SELLER = 1,
  BUYER = 2,
}

export enum PLATFORM {
  UNKNOWN = 0,
  PC_WEB_H5 = 1,
  IN_APP_H5 = 2,
  BROWSER_PC = 3,
  BROWSER_MOBILE = 4,
}

export enum OPEN_SOURCE {
  FROM_CATALOG = 0,
  OTHER = 1,
}

export enum ACTIONS {
  CONTACT_SELLER = "CONTACT_SELLER",
  COPY_LINK = "COPY_LINK",
  SHARE_TO_FRIENDS = "SHARE_TO_FRIENDS",
  PRODUCT_VIEW = "PRODUCT_VIEW",
  CATALOG_VIEW = "CATALOG_VIEW",
  SWIPE_IMAGE = "SWIPE_IMAGE",
}

export const THUMB_FAIL_LINK = "https://stc-zh5.zdn.vn/catalog/thumb-fail.png";

export const SEND_MSG_IOS_MAX_VERSION = 457;
export const SEND_MSG_ANDROID_MAX_VERSION = 621;
