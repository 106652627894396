import "./style.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useMemo } from "react";
interface CategoryCardProps {
  productId: number;
  image: string;
  name: string;
  price: string;
  path: string;
}

const owlClass = "category-card";

const parentWindow = window.parent;

const CategoryCard: React.FC<CategoryCardProps> = ({
  productId,
  image,
  name,
  price,
  path,
}) => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const zarsrc = query.get("zarsrc");
  const globalId = query.get("globalId");

  const cardPathName = useMemo(() => {
    if (zarsrc) {
      if (globalId) {
        return `/${path}&zarsrc=${zarsrc}&globalId=${globalId}`;
      }
      return `/${path}&zarsrc=${zarsrc}`;
    }

    return `/${path}`;
  }, [path, zarsrc, globalId]);

  return (
    <div
      className={owlClass}
      onClick={() => {
        const isPc = !!Number(query.get("ispc"));

        if (isPc) {
          const postData = {
            actionType: {
              action: "clickProductCard",
            },
            data: {
              productId,
            },
          };
          parentWindow.postMessage(postData, "*");
        }
        history.push(cardPathName, { fromCategoryPage: true });
      }}
    >
      <div className={`${owlClass}__image`}>
        <img
          src={image || "https://stc-zh5.zdn.vn/catalog/thumb-fail.png"}
          onError={(e: any) =>
            (e.target.src = "https://stc-zh5.zdn.vn/catalog/thumb-fail.png")
          }
          alt=""
        />
      </div>
      <div className={`${owlClass}__content`}>
        <h1 className={`${owlClass}__content__name`}>{name}</h1>
        <p className={`${owlClass}__content__price`}>{price}</p>
      </div>
    </div>
  );
};

export default CategoryCard;
