export const FETCH_INFO = "FETCH_INFO";
export const FETCH_INFO_ON_SUCCESS = "FETCH_INFO_ON_SUCCESS";
export const FETCH_INFO_ON_ERROR = "FETCH_INFO_ON_ERROR";

export interface FetchInfoAction {
  type: typeof FETCH_INFO;
}

export interface FetchedInfoOnSuccessAction {
  type: typeof FETCH_INFO_ON_SUCCESS;
  payload: InfoI;
}

export interface FetchedInfoOnErrorAction {
  type: typeof FETCH_INFO_ON_ERROR;
  payload: InfoI;
}

export type InfoActionTypes =
  | FetchInfoAction
  | FetchedInfoOnSuccessAction
  | FetchedInfoOnErrorAction;

export enum CODE {
  SUCCESS = 0,
  ERROR_INFO_NOTFOUND = -404,
}

export interface InfoI {
  data: {
    product_info: {
      catalogId: number;
      createdTime: number;
      currencyUnit: string;
      description: string;
      id: number;
      name: string;
      productPhotos: Array<string> | null;
      ownerId: number;
      price: number | string;
      strPrice: string;
      noisedUserId: string;
      mobileNoisedId: string;
      ownerAvatarUrl: string;
      ownerDisplayName: string;
      me: boolean;
      pid?: string;
      uid: number;
    },
    uid?: number;
  };
  error_code: CODE;
  error_message: string;
}

export interface InfoStateI {
  data: InfoI | null;
  isLoading: boolean;
  error: boolean;
}
