import { TFunction } from "react-i18next";
import formatCurrency from "./format-currency";

const getPrice = (price: number | string, t: TFunction<"translation">) => {
  if (typeof price === "string") {
    if (price) {
      return price;
    }
    return t("contact");
  }

  if (price > 0) {
    return formatCurrency("vi", price).slice(0, -1);
  }

  return t("contact");
};

export default getPrice;
