import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// @ts-ignore
import ZJSBridge from "zalo-js-bridge";
import copy from "copy-to-clipboard";

import { useOnClickOutside } from "../../utils";

import "./style.scss";
import { ACTIONS } from "../../constant";

const owlClass = "drop-up";

interface ShareProps {
  onTrackingAction: (action: string) => void;
}

var parentWindow = window.parent;

let linkOrigin = window.location.origin;

const Share: React.FC<ShareProps> = (props) => {
  const ref = useRef(null);
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isCopiedLink, setIsCopiedLink] = useState(false);

  const query = new URLSearchParams(useLocation().search);

  const linkText = useMemo(
    () => `${linkOrigin}/?pid=${query.get("pid")}`,
    [query]
  );

  const handlerClickOutside = useCallback(() => {
    setIsOpen(false);
  }, []);

  useOnClickOutside(ref, handlerClickOutside);

  const onToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleCopy = useCallback(() => {
    const isPc = !!Number(query.get("ispc"));
    props.onTrackingAction(ACTIONS.COPY_LINK);
    if (isPc) {
      const isCopied = copy(linkText);
      if (isCopied) {
        const postData = {
          actionType: {
            action: "successCopy",
          },
        };
        setIsCopiedLink(true);
        parentWindow.postMessage(
          postData,
          navigator.userAgent.toLocaleLowerCase().indexOf("zalo")
            ? "*"
            : (process.env.REACT_APP_CHAT_ZALO as string)
        );
      } else {
        const postData = {
          actionType: {
            action: "failCopy",
          },
        };
        parentWindow.postMessage(
          postData,
          navigator.userAgent.toLocaleLowerCase().indexOf("zalo")
            ? "*"
            : (process.env.REACT_APP_CHAT_ZALO as string)
        );
      }
    } else {
      const isCopied = copy(linkText);
      if (isCopied) {
        setIsCopiedLink(true);
        ZJSBridge.Device.showToast("Đã sao chép link chia sẻ");
      } else {
        ZJSBridge.Device.showToast("Sao chép không thành công");
      }
    }
  }, [query, props, linkText]);

  const onCopyLink = useCallback(() => {
    handleCopy();
    setIsOpen(false);
  }, [handleCopy]);

  const onSendToFriends = useCallback(() => {
    const isPc = !!Number(query.get("ispc"));
    props.onTrackingAction(ACTIONS.SHARE_TO_FRIENDS);
    if (isPc) {
      const postData = {
        actionType: {
          action: "shareLink",
        },
        data: {
          link: linkText,
        },
      };
      parentWindow.postMessage(
        postData,
        navigator.userAgent.toLocaleLowerCase().indexOf("zalo")
          ? "*"
          : (process.env.REACT_APP_CHAT_ZALO as string)
      );
    } else {
      ZJSBridge.Zalo.openShareSheet({
        type: 4, // share link type = 4
        link: linkText,
        chatOnly: 0, // on: 1; off 0: share on timeline
      });
    }

    setIsOpen(false);
  }, [linkText, query]);

  return (
    <div className={owlClass} ref={ref}>
      <button className={`${owlClass}__share-btn`} onClick={onToggle}>
        <label className={`${owlClass}__share-btn__label`}>{t("share")}</label>
      </button>
      {isOpen && (
        <div className={`${owlClass}__content`}>
          <span onClick={onCopyLink}>
            {isCopiedLink ? t("copied-link") : t("copy-link")}
          </span>
          <span onClick={onSendToFriends}>{t("send-to-friends")}</span>
        </div>
      )}
    </div>
  );
};

export default Share;
